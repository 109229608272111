import {Vue} from "vue-property-decorator";
import validator from "validator";
import {orderService, userService} from "@/services";
import strongPasswordOptions = validator.strongPasswordOptions;

export type VForm = Vue & { validate: () => boolean, resetValidation: () => void, reset: () => void }

export const requiredRule: Function = (message) => {
    return (v) => {
        return !!v || (message || 'Pole jest wymagane')
    }
}

export const usernameRule: Function = (message) => {
    return (v) => {
        return ((!!v && /^[a-zA-Z]+(?:\.[a-zA-Z]+)*$/.test(v) && validator.isLowercase(v))) || (message || 'Wymagany format: małe litery, kropka (.) tylko między literami')
    }
}

export const passwordRule: Function = (message) => {
    return (v) => {
        return (!!v && validator.isStrongPassword(v, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1
        } as strongPasswordOptions)) || (message || 'Wymagany format: Minimum 8 znaków, w tym co najmniej: 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny')
    }
}

export const systemAddressRule: Function = (message) => {
    return (v) => {
        return ((!!v && /^[a-z]+(?:-[a-z]+)*$/.test(v) && validator.isLowercase(v))) || (message || 'Wymagany format: małe litery, znak myślnika (-)')
    }
}

export const systemAddressClientRule: Function = (message) => {
    return (v) => {
        return ((!!v && /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(v) && validator.isLowercase(v))) || (message || 'Adres systemu nie jest poprawnym adresem www')
    }
}

export const integerNonZeroNumberRule: Function = (message) => {
    return (v) => {

        if(typeof v === 'string'){
            v = parseInt(v);
        }

        return (v > 0) || (message || 'Wymagana liczba naturalna większa od zera.')
    }
}

export const minLengthRule: Function = (length, message) => {
    return (v) => {
        return (!!v && v.length > length) || (message || `Minimalna długość to ${length}`)
    }
}

export const maxLengthRule: Function = (length, message) => {
    return (v) => {
        return (!!v && v.length < length) || (message || `Maksymalna długość to ${length}`)
    }
}

export const emailRule: Function = (message) => {
    return (v) => {
        return (!!v && validator.isEmail(v)) || (message || 'Format adresu email jest nieprawidłowy');
    }
}

export const compareRule: Function = (context, formDataName, compareFieldName1, compareFieldName2, message) => {
    return ((v) => {
        return (!!v && context[formDataName] && context[formDataName][compareFieldName1] === context[formDataName][compareFieldName2]) || (message || 'Pola nie są identyczne')
    });
}

export const usernameExistRule: Function = (message) => {
    return async (v) => {
        return (!(await userService.usernameExists(v)) || (message || 'Podana nazwa użytkownika jest już zajęta'));
    }
}

export const systemAddressExistRule: Function = (message) => {
    return async (v) => {
        return (!(await orderService.systemAddressExists(v)) || (message || 'Podany adres systemu istnieje już w innym zamówieniu'));
    }
}

export const emailExistRule: Function = (message) => {
    return async (v) => {
        return (!(await userService.emailExists(v)) || (message || 'Podany adres email jest już zajęty'));
    }
}
